import * as types from '../actions/types';
import { setCookie } from '../utils';
import { setCurrentUserData } from '../services/currentUser.service';

export default function(state = [], action) {
  const response = action.response;
  switch(action.type) {
    case types.LOGIN_USER:
      return {...state, loading: true}
    case types.LOGIN_USER_SUCCESS:
      setCurrentUserData(response.data);
      return { ...state, response, loading: undefined, isLoggedIn: true };
    case types.LOGIN_USER_ERROR:
      return { ...state, response, loading: undefined };
    default:
      return state;
  }
};