import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import configureStore from './store/configureStore';

import { Provider } from "react-redux";
// import AuthenticatedRoutes from "components/AuthenticatedRoutes/AuthenticatedRoutes.jsx";
import { indexRoutes, privateRoutes } from "./routes/index.js";
//
// import AuthLayout from "layouts/Auth.js";
// import RtlLayout from "layouts/RTL.js";
// import AdminLayout from "layouts/Admin.js";

// import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css'
// import './global.scss';

import * as serviceWorker from './serviceWorker';
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ToastContainer position={toast.POSITION.TOP_RIGHT}/>
    <Router>
      <Switch>
        {indexRoutes.map((prop, key) => {
          return (
            <Route
              path={prop.path}
              key={key}
              component={prop.component}
            />
          );
        })}
        {/*<AuthenticatedRoutes>*/}
        {/*  {privateRoutes.map((prop, key) => {*/}
        {/*    return (*/}
        {/*      <Route*/}
        {/*        path={prop.path}*/}
        {/*        key={key}*/}
        {/*        component={prop.component}*/}
        {/*      />*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</AuthenticatedRoutes>*/}
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
