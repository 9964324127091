import React, {Component} from 'react';
import '../../styles/style.css'
import nextImage from '../../assets/images/next.png'
import {Button, Input, CardHeader} from 'reactstrap';
import repeatImage from "../../assets/images/repeat.png";

class Search extends Component {
  render() {
    return (
      <section className="banner d-flex align-items-center justify-content-center flex-column">
        <div className="text-center">
          <h3 className="heading-1 banner-text-color">Buy direct, No Middle man </h3>
          <h1 className="banner-text-color heading-2 mb-0">Buy & Sell Livestock</h1>
          <h2 className="banner-text-color heading-2">Pay Direct, Organise Transport</h2>
          <p className="paragraph banner-text-color mb-0 px-2">Search from over 2000+ Active Ads in 29+ Categories for
            Free</p>
        </div>
        <form
          className="banner__form banner__form--spacing d-flex flex-column flex-md-row w-100 align-items-center p-2">
          <select
            className="banner__form-input banner__form-input--medium mb-3 mb-md-0 form-custom-input mr-2 form-control custom-select">
            <option>Looking for classified</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
          <select
            className="banner__form-input banner__form-input--narrow mb-3 mb-md-0 form-custom-input mr-2 form-control custom-select">
            <option>Select category</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
          <div className="banner__form-input position-relative">
            <input type="email" className="form-custom-input w-100" placeholder="Enter Keyword Here" />
            <span
              className="banner__form-input-action-button d-flex justify-content-center align-items-center position-absolute">
                    <img src={repeatImage} />
                </span>
          </div>
        </form>
      </section>
  )}}

export default Search;
