import React, { Component } from 'react';
import '../../styles/style.css'
import brandLogo from '../../assets/images/brand-logo.png'
import appStoreImage from '../../assets/images/app-store.png'
import callImage from '../../assets/images/call.png'
import emailImage from '../../assets/images/email.png'
import { Button, Input, CardHeader } from 'reactstrap';

class FooterInfo extends Component {
  render() {
    return(
        <footer className="main-footer footer-container">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-3">
                <img src={brandLogo} className="main-footer__logo img-fluid" alt="Company logo" />
                  <p className="mb-4">
                    There are many variations of assages of Lorem Ipsum available, but the rity have suffered
                    alteration in
                    some frm, by injected humour.
                  </p>
                  <img src={appStoreImage} alt="app store" className="pt-4" />
              </div>

              <div className="col-12 col-lg-2">
                <h6 className="main-footer__heading">About us</h6>
                <span className="main-footer__heading-divider d-block"></span>
                <a href="" className="main-footer__links-top-spacing d-inline-block">Our Product</a>
                <a href="">Documentation</a>
                <a href="">Our Services</a>
                <a href="">Apps Download</a>
              </div>
              <div className="col-12 col-lg-2">
                <a href="" className="main-footer__links-top-spacing-xl d-inline-block">Get Started Us</a>
                <a href="">Contact Us</a>
                <a href="">Needs Helps?</a>
                <a href="">Join With Us</a>
                <a href="">Ask Question</a>
                <a href="">Newsletters</a>
              </div>
              <div className="col-12 col-lg-2">
                <h6 className="main-footer__heading">About us</h6>
                <span className="main-footer__heading-divider d-block"></span>
                <a href="" className="main-footer__links-top-spacing d-inline-block">Marketplace</a>
                <a href="">Documentation</a>
                <a href="">Customers</a>
                <a href="">Carrers</a>
              </div>
              <div className="col-12 col-lg-3">
                <h6 className="main-footer__heading">Communication</h6>
                <span className="main-footer__heading-divider d-block"></span>
                <div
                  className="main-footer__communication-list main-footer__links-top-spacing d-flex align-items-center">
                  <img src={callImage} alt="call" className="main-footer__icon-img" />
                    <p className="main-footer__communication-item mb-0">
                      <span className="d-block">Call Us</span>
                      <a href="tel:+01712345678">+ 017 123 456 78</a>
                    </p>
                </div>
                <div className="d-flex align-items-center">
                  <img src={emailImage} alt="call" className="main-footer__icon-img" />
                    <p className="main-footer__communication-item mb-0">
                      <span className="d-block">Send Message</span>
                      <a href="mailto:seylon111@gmail.com">seylon111@gmail.com</a>
                    </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
    )
  }
}

export default FooterInfo;
