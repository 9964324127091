import { AxiosService } from "./axios.service";

class AppConfig {
    static instance;
    static appSettings;

    static getInstance() {
        if (!AppConfig.instance) {
            AppConfig.instance = new AppConfig();
            AppConfig.instance.loadAppSettings();
        }
        return AppConfig.instance;
    }
    
    loadAppSettings() {
        return new Promise((resolve, reject) => {
            if (!AppConfig.appSettings) {
                AxiosService.get('/appSettings.json').then(res => {
                    AppConfig.appSettings = res;
                    resolve(AppConfig.appSettings);
                });
            } else {
                resolve(AppConfig.appSettings);
            }
        })
    }

    async getBaseUrl() {
        return await process.env.REACT_APP_BASE_URL;
    }
    
    async getTablePageRowCount() {
        const appSettings = await this.loadAppSettings();
        return appSettings.tablePageRowCount;
    }

}
export const AppSettingsService = AppConfig.getInstance();