import { put, call } from 'redux-saga/effects';
import { registerUserService, loginUserService } from '../services/users.services';

import * as types from '../actions/types'

export function* loginSaga(payload) {
  try {
    const response = yield call(loginUserService, payload);
    response && payload.callback && payload.callback()
    yield put({ type: types.LOGIN_USER_SUCCESS, response});
  } catch(error) {
    yield put({ type: types.LOGIN_USER_ERROR, error });
  }
}


export function* registerSaga(payload) {
  try {
    const response = yield call(registerUserService, payload);
    yield [
      put({ type: types.REGISTER_USER_SUCCESS, response })
    ];
  } catch(error) {
    yield [
      put({ type: types.REGISTER_USER_ERROR, error })
    ];
  }
}