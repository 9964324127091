import React, {Component} from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { toast } from "react-toastify";
import swarmImage from '../../assets/images/brand/swarm.png';
import userImage from '../../assets/images/user.png';
import LoadingOverlay from 'react-loading-overlay';
import { loginUserAction } from '../../actions/authenticationActions';
import { getCurrentUserData } from '../../services/currentUser.service';

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }

  componentDidMount() {
    // document.title = 'Farmtrade Login';
  }

  // goToForgotPassword(){
  //   this.props.history.push('/auth/forgot-password');
  // }

  componentWillReceiveProps(props) {
    console.log('login', props)

    if(this.state.isLoading && this.state.isLoading !== props.response.login.loading) {
      this.setState({
        isLoading: props.response.login.loading
      })
    }
  }

  handleSubmit(event) {
    if (this.state.email && this.state.password) {
      this.setState({}, () => {
        const data = {
          email:this.state && this.state.email,
          password:this.state && this.state.password
        };

        this.props.dispatch(loginUserAction(data, () => {
          const userData = getCurrentUserData();
          if(userData){
            this.props.history.push(`/home`);
          }
        }));
      });
      event.preventDefault();
    } else {
      if(!this.state.email) {
        toast.error('Email is required')
      } else {
        toast.error('Password is required')
      }
    }
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  onClose = () => {
    this.setState({ erMessage: "" })
  }
  
  render() {

  return (
    <React.Fragment>
      <section className="page-form" style={{ marginTop : '0px', paddingTop:'140px', paddingBottom:'50px' }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 mx-auto">
              <h3 className="page-form__title mb-4 mt-0">
                <img src={userImage} alt="user" className="mr-3"/>
                Sign in.
              </h3>
              <p className="mb-0 page-form__description">
                Morbi et sagittis dui, sed fermentum ante. Pellentesque molestie
                sit amet dolor vel euismod.
              </p>
              <form className="page-form__form" name="loginForm">
                <div className="form-group mb-5">
                  <label htmlFor="email" className="mb-4">Email</label>
                  <input type="email" className="page-form__custom-input form-control" id="email"
                         name="email"
                         aria-describedby="emailHelp" placeholder="Email:"
                         onChange= {(event) => this.handleUserInput(event) }
                         value={this.state.email}
                         required
                  />
                </div>
                <div className="form-group mb-5">
                  <label htmlFor="password" className="mb-4">Password</label>
                  <input type="password" className="page-form__custom-input form-control" id="password"
                         name="password"
                         placeholder="Password"
                         onChange= {(event) => this.handleUserInput(event) }
                         value={this.state.password}
                         required
                  />
                </div>
                <div className="d-flex">
                  <div className="form-check pl-0">
                    <input type="checkbox" className="form-check-input" id="policy-check" />
                    <label className="form-check-label d-flex align-items-center" htmlFor="policy-check">
                      Remember me
                    </label>
                  </div>
                  <a href="#" className="form-check-success-text ml-auto">
                    Forget Password?
                  </a>
                </div>
                {
                  (this.state.isLoading)
                    ? <div className="sk-spinner sk-spinner-pulse"></div>
                    : <button type="button" className="page-form__form-action w-100 btn btn-primary"
                              disabled={!this.state.email || !this.state.password} onClick={ (event) => this.handleSubmit(event)}>
                      Login Now
                    </button>
                }
              </form>
              <p className="text-center mt-4 page-form__footer-helper-text">
                <Link to="/sign-up" className="form-check-success-text">New user? </Link>
                Don't have an account?
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
}

const mapStateToProps = (response) => ({response});
export default connect(mapStateToProps, null)(LoginPage);
