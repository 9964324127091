import { BASE_URL } from "../common/constants";
import { getToken, getUserId } from "../utils/common";
import { AxiosService, EndPoints, getUrl } from '../utils';

export const loginUserService = async(request) => {
  return await AxiosService.post(await getUrl(EndPoints.LOGIN_API_ENDPOINT), request.user && request.user);
};


export const registerUserService = async (request) => {
  return await AxiosService.post(await getUrl(EndPoints.REGISTER_API_ENDPOINT), request.user);
};
