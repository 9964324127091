import React, { Component } from 'react';
import brandLogo from '../../assets/images/brand-logo.png'
import searchImage from '../../assets/images/search.png'
import addToBasket from '../../assets/images/add-to-basket.png'
import repeatImage from '../../assets/images/repeat.png'
import {Link} from 'react-router-dom';

import { Button, Input, CardHeader } from 'reactstrap';

class Header extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <header className={`header d-flex align-items-center header--active`} id="header">
          <a href="" className="header__logo">
            <img src={brandLogo} alt="company logo" />
          </a>
          <div className="container">
            <div className="row">
              <div className="col-9 offset-2">
                <nav className="navbar navbar-expand-lg">
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto d-flex w-100">
                      <li className="nav-item">
                        <a className="nav-link active" href="#">Home <span className="sr-only">(current)</span></a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">About us</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">Contact us</a>
                      </li>
                      <li className="nav-item ml-auto">
                        <a className="nav-link d-flex" href="#">
                          <img src={searchImage} alt="search icon" className="mr-2" />
                            Search
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link d-flex align-items-center" href="#">
                          <img src={addToBasket} alt="basket icon" className="mr-2" />
                            Cart item
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <div className="header__button custom-primary-button btn">
            <Link to={`/login`} style={{color:'#FFF', textDecoration:'none'}}>Sign In / Sign Up</Link>
          </div>
        </header>
    )
  }
}

export default Header;
