import React, { Component } from 'react';
import '../../styles/style.css'
import brandLogo from '../../assets/images/brand-logo.png'
import appStoreImage from '../../assets/images/app-store.png'
import callImage from '../../assets/images/call.png'
import emailImage from '../../assets/images/email.png'
import { Button, Input, CardHeader } from 'reactstrap';

class Footer extends Component {
  render() {
    return(
      <footer className="d-flex footer-copy-right d-flex align-items-center">
        <div className="container">
          <div className="d-flex flex-column flex-sm-row">
            <span className="footer-copy-right-text text-center">
              Copywright by <a href="#" className="text-white">@24webpro</a>.All Rights Reserved
            </span>
            <nav className="ml-auto">
              <a href="" className="mr-4 footer-copy-right__link">Privacy</a>
              <a href="" className="mr-4 footer-copy-right__link">Terms & Conditions</a>
              <a href="" className="mr-4 footer-copy-right__link">Blog</a>
            </nav>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;
