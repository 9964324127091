export const LOAD_INITIAL_DATA = 'LOAD_INITIAL_DATA';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const LOAD_CREDIT = 'LOAD_CREDIT';
export const LOAD_CREDIT_SUCCESS = 'LOAD_CREDIT_SUCCESS';
export const LOAD_CREDIT_ERROR = 'LOAD_CREDIT_ERROR';

export const LOAD_CREDIT_BY_PROJECT = 'LOAD_CREDIT_BY_PROJECT';
export const LOAD_CREDIT_BY_PROJECT_SUCCESS = 'LOAD_CREDIT_BY_PROJECT_SUCCESS';
export const LOAD_CREDIT_BY_PROJECT_ERROR = 'LOAD_CREDIT_BY_PROJECT_ERROR';

//Profile Page
export const LOAD_COUNTRY_LIST = 'LOAD_COUNTRY_LIST';
export const GET_COUNTRY_LIST_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS'
export const GETTING_COUNTRY_LIST = 'GETTTING_COUNTRY_LIST'
export const GET_COUNTRY_LIST_ERROR = 'GET_COUNTRY_LIST_ERROR';

export const VALIDATE_INVITE_CODE = 'onboading/VALIDATE_INVITE_CODE';
export const VALIDATE_INVITE_CODE_SUCCESS = 'onboading/VALIDATE_INVITE_CODE_SUCCESS';
export const VALIDATE_INVITE_CODE_ERROR = 'onboading/VALIDATE_INVITE_CODE_ERROR';

export const SET_PASSWORD = 'onboading/SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'onboading/SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_ERROR = 'onboading/SET_PASSWORD_ERROR';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';


export const ON_BOARDING_STEPS = 'onboading/ON_BOARDING_STEPS';
export const ON_BOARDING_STEPS_SUCCESS = 'onboading/ON_BOARDING_STEPS_SUCCESS';
export const ON_BOARDING_STEPS_ERROR = 'onboading/ON_BOARDING_STEPS_ERROR';

export const LOAD_TIMEZONE_LIST = 'LOAD_TIMEZONE_LIST';
export const LOAD_TIMEZONE_LIST_SUCCESS = 'LOAD_TIMEZONE_LIST_SUCCESS';
export const LOAD_TIMEZONE_LIST_ERROR = 'LOAD_TIMEZONE_LIST_ERROR';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
export const UPDATE_USER_DATA_ERROR = 'UPDATE_USER_DATA_ERROR';

export const LOAD_ONBOARDIING_SKILLS = 'LOAD_ONBOARDIING_SKILLS';
export const LOAD_ONBOARDIING_SKILLS_SUCCESS = 'LOAD_ONBOARDIING_SKILLS_SUCCESS';
export const LOAD_ONBOARDIING_SKILLS_ERROR = 'LOAD_ONBOARDIING_SKILLS_ERROR';

export const SAVE_ONBOARDING_PROGRESS = 'SAVE_ONBOARDING_PROGRESS';
export const SAVE_ONBOARDING_PROGRESS_SUCCESS = 'SAVE_ONBOARDING_PROGRESS_SUCCESS';
export const SAVE_ONBOARDING_PROGRESS_ERROR = 'SAVE_ONBOARDING_PROGRESS_ERROR';

export const LOAD_SKILLS = 'LOAD_SKILLS';
export const LOAD_SKILLS_SUCCESS = 'LOAD_SKILLS_SUCCESS';
export const LOAD_SKILLS_ERROR = 'LOAD_SKILLS_ERROR';

export const GET_CONTRACTS_LIST = 'GET_CONTRACTS_LIST';
export const GET_CONTRACTS_LIST_SUCCESS = 'GET_CONTRACTS_LIST_SUCCESS'
export const GET_CONTRACTS_LIST_ERROR = 'GET_CONTRACTS_LIST_ERROR'

export const UPLOAD_CONTRACTS = 'UPLOAD_CONTRACTS';
export const UPLOAD_CONTRACTS_SUCCESS = 'UPLOAD_CONTRACTS_SUCCESS';
export const UPLOAD_CONTRACTS_ERROR = 'UPLOAD_CONTRACTS_ERROR';

export const GET_ONBOARDING_STATUS = 'GET_ONBOARDING_STATUS';
export const GET_ONBOARDING_STATUS_SUCCESS = 'GET_ONBOARDING_STATUS_SUCCESS';
export const GET_ONBOARDING_STATUS_ERROR = 'GET_ONBOARDING_STATUS_ERROR';

export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_ERROR = 'GET_USER_DATA_ERROR';

export const GET_STATUS = 'GET_STATUS';
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const GET_STATUS_ERROR = 'GET_STATUS_ERROR';

export const GET_USER_CREDIT = 'GET_USER_CREDIT';
export const GET_USER_CREDIT_SUCCESS = 'GET_USER_CREDIT_SUCCESS';
export const GET_USER_CREDIT_ERROR = 'GET_USER_CREDIT_ERROR';

export const GET_COMMENT = 'GET_COMMENT';
export const GET_COMMENT_SUCCESS = 'GET_COMMENT_SUCCESS';
export const GET_COMMENT_ERROR = 'GET_COMMENT_ERROR';

export const POST_COMMENT = 'POST_COMMENT';
export const POST_COMMENT_SUCCESS = 'POST_COMMENT_SUCCESS';
export const POST_COMMENT_ERROR = 'POST_COMMENT_ERROR';

export const POST_BIDMODAL = 'POST_BIDMODAL';
export const POST_BIDMODAL_SUCCESS = 'POST_BIDMODAL_SUCCESS';
export const POST_BIDMODAL_ERROR = 'POST_BIDMODAL_ERROR';

export const PUT_BIDMODAL = 'PUT_BIDMODAL';
export const PUT_BIDMODAL_SUCCESS = 'PUT_BIDMODAL_SUCCESS';
export const PUT_BIDMODAL_ERROR = 'PUT_BIDMODAL_ERROR';

export const GET_BIDMODAL = 'GET_BIDMODAL';
export const GET_BIDMODAL_SUCCESS = 'GET_BIDMODAL_SUCCESS';
export const GET_BIDMODAL_ERROR = 'GET_BIDMODAL_ERROR';

export const GET_OPEN_CONTRACT = 'GET_OPEN_CONTRACT';
export const GET_OPEN_CONTRACT_SUCCESS = 'GET_OPEN_CONTRACT_SUCCESS';
export const GET_OPEN_CONTRACT_ERROR = 'GET_OPEN_CONTRACT_ERROR';

export const GET_CONTRACT_DATA = 'GET_CONTRACT_DATA';
export const GET_CONTRACT_DATA_SUCCESS = 'GET_CONTRACT_DATA_SUCCESS';
export const GET_CONTRACT_DATA_ERROR = 'GET_CONTRACT_DATA_ERROR';

export const GET_BID_DETAILS = 'GET_BID_DETAILS';
export const GET_BID_DETAILS_SUCCESS = 'GET_BID_DETAILS_SUCCESS';
export const GET_BID_DETAILS_ERROR = 'GET_BID_DETAILS_ERROR';

export const GET_CONTRACTHISTORY = 'GET_CONTRACTHISTORY';
export const GET_CONTRACTHISTORY_SUCCESS = 'GET_CONTRACTHISTORY_SUCCESS';
export const GET_CONTRACTHISTORY_ERROR = 'GET_CONTRACTHISTORY_ERROR';

export const GET_STORY = 'GET_CONTRACTHISTORY';
export const GET_STORY_SUCCESS = 'GET_STORY_SUCCESS';
export const GET_STORY_ERROR = 'GET_STORY_ERROR';

export const PUT_COMMENT = 'PUT_COMMENT';
export const PUT_COMMENT_SUCCESS = 'PUT_COMMENT_SUCCESS';
export const PUT_COMMENT_ERROR = 'PUT_COMMENT_ERROR';

export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_ERROR = 'DELETE_COMMENT_ERROR';

export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';
export const CHANGE_USER_STATUS_SUCCESS = 'CHANGE_USER_STATUS_SUCCESS';
export const CHANGE_USER_STATUS_ERROR = 'CHANGE_USER_STATUS_ERROR';

export const PUT_BID = 'PUT_BID';
export const PUT_BID_SUCCESS = 'PUT_BID_SUCCESS';
export const PUT_BID_ERROR = 'PUT_BID_ERROR';

export const UPDATE_CONTRACT_STATUS = 'UPDATE_CONTRACT_STATUS'
export const UPDATE_CONTRACT_STATUS_SUCCESS = 'UPDATE_CONTRACT_STATUS_SUCCESS'
export const UPDATE_CONTRACT_STATUS_ERROR = 'UPDATE_CONTRACT_STATUS_ERROR'
