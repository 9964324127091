import React, {Component} from 'react';
import '../../styles/style.css'
import automotive from '../../assets/images/category/automotive.png'
import {Button, Input, CardHeader} from 'reactstrap';

class Category extends Component {
  render() {
    return (

      <section className="category text-center section-spacing-y">
        <h5 className="section-title-1 section-title-spacing section-title-size mb-0 mx-auto">
          Buy And Sell Everything From Used Our Top Cetagory
          Or Cetagory For Property, Jobs And More
        </h5>
        <div className="container">
          <div className="row">
            <div className="col-2">
              <div className="category-card category-card--spacing">
                <img className="category-card-img-icon" src={automotive} alt="category" />
                  <h6 className="category-card-title mb-0">Automotive</h6>
                  <p className="category-card-description mb-0">09 Ads</p>
              </div>
            </div>

            <div className="col-2">
              <div className="category-card category-card--spacing">
                <img className="category-card-img-icon" src={automotive} alt="category" />
                  <h6 className="category-card-title mb-0">Automotive</h6>
                  <p className="category-card-description mb-0">09 Ads</p>
              </div>
            </div>

            <div className="col-2">
              <div className="category-card category-card--spacing">
                <img className="category-card-img-icon" src={automotive} alt="category" />
                  <h6 className="category-card-title mb-0">Automotive</h6>
                  <p className="category-card-description mb-0">09 Ads</p>
              </div>
            </div>

            <div className="col-2">
              <div className="category-card category-card--spacing">
                <img className="category-card-img-icon" src={automotive} alt="category" />
                  <h6 className="category-card-title mb-0">Automotive</h6>
                  <p className="category-card-description mb-0">09 Ads</p>
              </div>
            </div>

            <div className="col-2">
              <div className="category-card category-card--spacing">
                <img className="category-card-img-icon" src={automotive} alt="category" />
                  <h6 className="category-card-title mb-0">Automotive</h6>
                  <p className="category-card-description mb-0">09 Ads</p>
              </div>
            </div>

            <div className="col-2">
              <div className="category-card category-card--spacing">
                <img className="category-card-img-icon" src={automotive} alt="category" />
                  <h6 className="category-card-title mb-0">Automotive</h6>
                  <p className="category-card-description mb-0">09 Ads</p>
              </div>
            </div>

            <div className="col-2">
              <div className="category-card category-card--spacing">
                <img className="category-card-img-icon" src={automotive} alt="category" />
                  <h6 className="category-card-title mb-0">Automotive</h6>
                  <p className="category-card-description mb-0">09 Ads</p>
              </div>
            </div>

            <div className="col-2">
              <div className="category-card category-card--spacing">
                <img className="category-card-img-icon" src={automotive} alt="category" />
                  <h6 className="category-card-title mb-0">Automotive</h6>
                  <p className="category-card-description mb-0">09 Ads</p>
              </div>
            </div>

            <div className="col-2">
              <div className="category-card category-card--spacing">
                <img className="category-card-img-icon" src={automotive} alt="category" />
                  <h6 className="category-card-title mb-0">Automotive</h6>
                  <p className="category-card-description mb-0">09 Ads</p>
              </div>
            </div>

            <div className="col-2">
              <div className="category-card category-card--spacing">
                <img className="category-card-img-icon" src={automotive} alt="category" />
                  <h6 className="category-card-title mb-0">Automotive</h6>
                  <p className="category-card-description mb-0">09 Ads</p>
              </div>
            </div>

            <div className="col-2">
              <div className="category-card category-card--spacing">
                <img className="category-card-img-icon" src={automotive} alt="category" />
                  <h6 className="category-card-title mb-0">Automotive</h6>
                  <p className="category-card-description mb-0">09 Ads</p>
              </div>
            </div>

            <div className="col-2">
              <div className="category-card category-card--spacing">
                <img className="category-card-img-icon" src={automotive} alt="category" />
                  <h6 className="category-card-title mb-0">Automotive</h6>
                  <p className="category-card-description mb-0">09 Ads</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Category;
