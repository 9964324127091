import React, {Component} from 'react';
import '../../styles/style.css'
import nextImage from '../../assets/images/next.png'
import {Button, Input, CardHeader} from 'reactstrap';

class Advertisement extends Component {
  render() {
    return (
      <section className="our-classified-ads section-spacing-y">
        <header className="our-classified-ads__header text-center">
          <h3 className="section-spacing-caption-title">BUY & SELL ONLINE</h3>
          <h1 className="section-main-title our-classified-ads__title-2">Our Classified Ads</h1>
          <span className="our-classified-ads__category-item d-inline-block">
                <span className="our-classified-ads__category-dot d-inline-block"></span>
                <span className="our-classified-ads__category-title d-inline-block">
                    NEW ADES
                </span>
            </span>
          <span className="our-classified-ads__category-item d-inline-block">
                <span className="our-classified-ads__category-dot d-inline-block"></span>
                <span className="our-classified-ads__category-title d-inline-block">
                    NEW ADES
                </span>
            </span>
          <span className="our-classified-ads__category-item d-inline-block">
                <span className="our-classified-ads__category-dot d-inline-block"></span>
                <span className="our-classified-ads__category-title d-inline-block">
                    NEW ADES
                </span>
            </span>
          <span className="our-classified-ads__category-item d-inline-block">
                <span className="our-classified-ads__category-dot d-inline-block"></span>
                <span className="our-classified-ads__category-title d-inline-block">
                    NEW ADES
                </span>
            </span>
        </header>
        <div className="container">
          <div className="row">

            <div className="col-12 col-md-6 col-lg-3">
              <div className="our-classified-ads__card position-relative">
                <span className="our-classified-ads__card-badge position-absolute d-flex justify-content-center align-items-center">
                  Discount 30%
                </span>
                <div className="our-classified-ads__card-media"></div>
                <div className="our-classified-ads__card-body">
                  <h6 className="our-classified-ads__card-title-1 mb-1">Electronics</h6>
                  <h6 className="our-classified-ads__card-title-2 mb-2">Samsung Galaxy S5.</h6>
                  <h6 className="our-classified-ads__card-description mb-3">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Louis, Missouri, US
                  </h6>
                  <div className="d-flex justify-content-between">
                    <h6 className="our-classified-ads__card-price">$13.00</h6>
                    <span>
                        <i className="fa fa-star-o fa-star-o--active" aria-hidden="true"></i>
                        <i className="fa fa-star-o fa-star-o--active" aria-hidden="true"></i>
                        <i className="fa fa-star-o fa-star-o--active" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                    </span>
                  </div>
                  <button className="our-classified-ads__card-btn btn w-100 mt-3">View Details</button>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="our-classified-ads__card">
                <div className="our-classified-ads__card-media"></div>
                <div className="our-classified-ads__card-body">
                  <h6 className="our-classified-ads__card-title-1 mb-1">Electronics</h6>
                  <h6 className="our-classified-ads__card-title-2 mb-2">Samsung Galaxy S5.</h6>
                  <h6 className="our-classified-ads__card-description mb-3">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Louis, Missouri, US
                  </h6>
                  <div className="d-flex justify-content-between">
                    <h6 className="our-classified-ads__card-price">$13.00</h6>
                    <span>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                    </span>
                  </div>
                  <button className="our-classified-ads__card-btn btn w-100 mt-3">View Details</button>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="our-classified-ads__card">
                <div className="our-classified-ads__card-media"></div>
                <div className="our-classified-ads__card-body">
                  <h6 className="our-classified-ads__card-title-1 mb-1">Electronics</h6>
                  <h6 className="our-classified-ads__card-title-2 mb-2">Samsung Galaxy S5.</h6>
                  <h6 className="our-classified-ads__card-description mb-3">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Louis, Missouri, US
                  </h6>
                  <div className="d-flex justify-content-between">
                    <h6 className="our-classified-ads__card-price">$13.00</h6>
                    <span>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                    </span>
                  </div>
                  <button className="our-classified-ads__card-btn btn w-100 mt-3">View Details</button>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="our-classified-ads__card">
                <div className="our-classified-ads__card-media"></div>
                <div className="our-classified-ads__card-body">
                  <h6 className="our-classified-ads__card-title-1 mb-1">Electronics</h6>
                  <h6 className="our-classified-ads__card-title-2 mb-2">Samsung Galaxy S5.</h6>
                  <h6 className="our-classified-ads__card-description mb-3">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Louis, Missouri, US
                  </h6>
                  <div className="d-flex justify-content-between">
                    <h6 className="our-classified-ads__card-price">$13.00</h6>
                    <span>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                    </span>
                  </div>
                  <button className="our-classified-ads__card-btn btn w-100 mt-3">View Details</button>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="our-classified-ads__card">
                <div className="our-classified-ads__card-media"></div>
                <div className="our-classified-ads__card-body">
                  <h6 className="our-classified-ads__card-title-1 mb-1">Electronics</h6>
                  <h6 className="our-classified-ads__card-title-2 mb-2">Samsung Galaxy S5.</h6>
                  <h6 className="our-classified-ads__card-description mb-3">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Louis, Missouri, US
                  </h6>
                  <div className="d-flex justify-content-between">
                    <h6 className="our-classified-ads__card-price">$13.00</h6>
                    <span>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                    </span>
                  </div>
                  <button className="our-classified-ads__card-btn btn w-100 mt-3">View Details</button>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="our-classified-ads__card">
                <div className="our-classified-ads__card-media"></div>
                <div className="our-classified-ads__card-body">
                  <h6 className="our-classified-ads__card-title-1 mb-1">Electronics</h6>
                  <h6 className="our-classified-ads__card-title-2 mb-2">Samsung Galaxy S5.</h6>
                  <h6 className="our-classified-ads__card-description mb-3">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Louis, Missouri, US
                  </h6>
                  <div className="d-flex justify-content-between">
                    <h6 className="our-classified-ads__card-price">$13.00</h6>
                    <span>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                    </span>
                  </div>
                  <button className="our-classified-ads__card-btn btn w-100 mt-3">View Details</button>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="our-classified-ads__card">
                <div className="our-classified-ads__card-media"></div>
                <div className="our-classified-ads__card-body">
                  <h6 className="our-classified-ads__card-title-1 mb-1">Electronics</h6>
                  <h6 className="our-classified-ads__card-title-2 mb-2">Samsung Galaxy S5.</h6>
                  <h6 className="our-classified-ads__card-description mb-3">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Louis, Missouri, US
                  </h6>
                  <div className="d-flex justify-content-between">
                    <h6 className="our-classified-ads__card-price">$13.00</h6>
                    <span>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                    </span>
                  </div>
                  <button className="our-classified-ads__card-btn btn w-100 mt-3">View Details</button>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="our-classified-ads__card">
                <div className="our-classified-ads__card-media"></div>
                <div className="our-classified-ads__card-body">
                  <h6 className="our-classified-ads__card-title-1 mb-1">Electronics</h6>
                  <h6 className="our-classified-ads__card-title-2 mb-2">Samsung Galaxy S5.</h6>
                  <h6 className="our-classified-ads__card-description mb-3">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Louis, Missouri, US
                  </h6>
                  <div className="d-flex justify-content-between">
                    <h6 className="our-classified-ads__card-price">$13.00</h6>
                    <span>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                    </span>
                  </div>
                  <button className="our-classified-ads__card-btn btn w-100 mt-3">View Details</button>
                </div>
              </div>
            </div>

          </div>
          <p className="section-action-line text-center mt-4 mb-0">
            We work creatively and specially for our clients.
            <span className="section-action-line__highlight">All classified ads
              <img src={nextImage} alt="next icon" className="section-action-line__highlight-icon" />
            </span>
          </p>
        </div>
      </section>
  )
  }
  }

  export default Advertisement;
