import React, {Component} from 'react';
import '../../styles/style.css'
import nextImage from '../../assets/images/next.png'
import {Button, Input, CardHeader} from 'reactstrap';

class LatestNews extends Component {
  render() {
    return (
      <section className="latest-news section-spacing-y">
        <h3 className="section-spacing-caption-title text-center">Our Blogs</h3>
        <h1 className="section-main-title latest-news__title-2 text-center">Our Latest News</h1>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="latest-news__card">
                <div className="latest-news__card-media"></div>
                <div className="latest-news__card-body bg-white">
                  <header className="d-inline-flex">
                                <span className="latest-news__card-quote-box d-inline-block">
                                    <img src="../../assets/images/our-work/quotation-marks.png" alt="quote" />
                                </span>
                    <div className="d-flex justify-content-center flex-column pl-3">
                      <h1 className="latest-news__card-header-title d-inline-block">
                        "Lorem Ipsum is simply dummy"
                      </h1>
                      <span className="latest-news__card-header-author d-block">
                                        Alisa brown
                                    </span>
                    </div>
                  </header>
                  <div className="latest-news__card-body-content">
                    <span className="latest-news__card-date-comments d-inline-block">
                        20 Octobar,2019
                    </span>
                    <span className="latest-news__card-date-comments latest-news__card-date-comments--spacing d-inline-block">
                        <i className="fa fa-commenting-o mr-3" aria-hidden="true"></i>
                        10 Comments
                    </span>
                    <h2 className="latest-news__card-post-title mt-2">
                      Single Frelancer Workplace dino.
                    </h2>
                    <h2 className="latest-news__card-post-description">
                      You should be able to find several ensable facts about the following paragraphs.
                      Ifre’s
                      at least one fact.
                    </h2>
                    <button className="btn our-classified-ads__card-btn w-100">
                      View Detail
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div className="latest-news__card">
                <div className="latest-news__card-media"></div>
                <div className="latest-news__card-body bg-white">
                  <header className="d-inline-flex">
                                <span className="latest-news__card-quote-box d-inline-block">
                                    <img src="../../assets/images/our-work/quotation-marks.png" alt="quote" />
                                </span>
                    <div className="d-flex justify-content-center flex-column pl-3">
                      <h1 className="latest-news__card-header-title d-inline-block">
                        "Lorem Ipsum is simply dummy"
                      </h1>
                      <span className="latest-news__card-header-author d-block">
                                        Alisa brown
                                    </span>
                    </div>
                  </header>
                  <div className="latest-news__card-body-content">
                                <span className="latest-news__card-date-comments d-inline-block">
                                    20 Octobar,2019
                                </span>
                    <span
                      className="latest-news__card-date-comments latest-news__card-date-comments--spacing d-inline-block">
                                    <i className="fa fa-commenting-o mr-3" aria-hidden="true"></i>
                                    10 Comments
                                </span>
                    <h2 className="latest-news__card-post-title mt-2">
                      Single Frelancer Workplace dino.
                    </h2>
                    <h2 className="latest-news__card-post-description">
                      You should be able to find several ensable facts about the following paragraphs.
                      Ifre’s
                      at least one fact.
                    </h2>
                    <button className="btn our-classified-ads__card-btn w-100">
                      View Detail
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div className="latest-news__card">
                <div className="latest-news__card-media"></div>
                <div className="latest-news__card-body bg-white">
                  <header className="d-inline-flex">
                                <span className="latest-news__card-quote-box d-inline-block">
                                    <img src="../../assets/images/our-work/quotation-marks.png" alt="quote" />
                                </span>
                    <div className="d-flex justify-content-center flex-column pl-3">
                      <h1 className="latest-news__card-header-title d-inline-block">
                        "Lorem Ipsum is simply dummy"
                      </h1>
                      <span className="latest-news__card-header-author d-block">
                                        Alisa brown
                                    </span>
                    </div>
                  </header>
                  <div className="latest-news__card-body-content">
                                <span className="latest-news__card-date-comments d-inline-block">
                                    20 Octobar,2019
                                </span>
                    <span
                      className="latest-news__card-date-comments latest-news__card-date-comments--spacing d-inline-block">
                                    <i className="fa fa-commenting-o mr-3" aria-hidden="true"></i>
                                    10 Comments
                                </span>
                    <h2 className="latest-news__card-post-title mt-2">
                      Single Frelancer Workplace dino.
                    </h2>
                    <h2 className="latest-news__card-post-description">
                      You should be able to find several ensable facts about the following paragraphs.
                      Ifre’s
                      at least one fact.
                    </h2>
                    <button className="btn our-classified-ads__card-btn w-100">
                      View Detail
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default LatestNews;
