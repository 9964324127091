import React, { Component } from "react";
import { connect } from "react-redux";

import { toast } from "react-toastify";
// import { ROLES } from "common/constants";
import LoadingOverlay from 'react-loading-overlay';

import Category from '../../components/Category/Category';
import Advertisement from '../../components/Advertisement/Advertisement';
import TopPlace from '../../components/Top-Place/TopPlace';
import LatestNews from '../../components/Latest-News/LatestNews';
import Search from "../../components/Search/Search";
import FooterInfo from "../../components/Footer-Info/FooterInfo";

export class HomePage extends Component {

  constructor(props) {
    super(props)
  }
  
  render() {
    return (
      <React.Fragment>
        <Search></Search>
        <Category></Category>
        <Advertisement></Advertisement>
        <TopPlace></TopPlace>
        <LatestNews></LatestNews>
        <FooterInfo></FooterInfo>
      </React.Fragment>
    );
  }
}

export default HomePage;