import React, {Component} from 'react';
import '../../styles/style.css'
import nextImage from '../../assets/images/next.png'
import {Button, Input, CardHeader} from 'reactstrap';

class TopPlace extends Component {
  render() {
    return (
      <section className="top-place section-spacing-y">
        <h3 className="section-spacing-caption-title text-center">Top Places</h3>
        <h1 className="section-main-title top-place__title-2 text-center">Our Top Locations</h1>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-md-5">
                  <div className="top-place__item w-100 position-relative">
                    <h2 className="top-place__item-heading text-center">Kazakhstan</h2>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="top-place__item w-100 position-relative">
                    <h2 className="top-place__item-heading text-center">Kazakhstan</h2>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="top-place__item w-100 position-relative">
                    <h2 className="top-place__item-heading text-center">Kazakhstan</h2>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="top-place__item w-100 position-relative">
                    <h2 className="top-place__item-heading text-center">Kazakhstan</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="top-place__item top-place__item--portrait w-100 position-relative">
                <h2 className="top-place__item-heading text-center">Kazakhstan</h2>
              </div>
            </div>
          </div>
          <p className="section-action-line top-place__section-action-line text-center mb-0">
            We work creatively and specially for our clients.
            <span className="section-action-line__highlight">All classified ads
              <img src={nextImage} alt="next icon" className="section-action-line__highlight-icon" />
            </span>
          </p>
        </div>
      </section>
    )
  }
}

export default TopPlace;
