import { fork } from 'redux-saga/effects';
import {
    watchUserAuthentication,
    watchProfileActions,
    watchCreditAction,
} from './watchers';

export default function* startForman() {
    yield fork(watchUserAuthentication);
    // yield fork(watchProfileActions);
    // yield fork(watchCreditAction);
}
