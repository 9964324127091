import React, {Component} from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import swarmImage from '../../assets/images/brand/swarm.png';
import userImage from '../../assets/images/user.png';
import { registerUserAction } from "../../actions/authenticationActions";
import { getCurrentUserData } from "../../services/currentUser.service";

export class Signup extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      emailError: false,
      passwordError: false,
      isLoggedIn: false,
      erMessage: "",
      isLoading: false
    }
  }

  // goToForgotPassword(){
  //   this.props.history.push('/auth/forgot-password');
  // }

  handleSubmit(event) {
    if (this.state.firstName && this.state.lastName && this.state.email && this.state.password) {
      this.setState({}, () => {
        const data = {
          email:this.state && this.state.email,
          password:this.state && this.state.password,
          first_name:this.state && this.state.firstName,
          last_name:this.state && this.state.lastName,
        };

        this.props.dispatch(registerUserAction(data, () => {
          toast.success()
            this.props.history.push(`/login`);
        }));
      });
      event.preventDefault();
    }
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  onClose = () => {
    this.setState({ erMessage: "" })
  }
  
  render() {
  return (
    <React.Fragment>
      <section className="page-form" style={{ marginTop : '0px', paddingTop:'140px', paddingBottom:'50px' }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 mx-auto">
              <h3 className="page-form__title mb-4 mt-0">
                <img src={userImage} alt="user" className="mr-3"/>
                Sign up.
              </h3>
              <p className="mb-0 page-form__description">
                Morbi et sagittis dui, sed fermentum ante. Pellentesque molestie
                sit amet dolor vel euismod.
              </p>

              <form className="page-form__form">
                <div className="row form-group mb-4">
                  <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                    <label htmlFor="email" className="mb-4">First Name</label>
                    <input
                      type="text"
                      className="page-form__custom-input form-control"
                      id="firstName"
                      name="firstName"
                      aria-describedby="emailHelp"
                      placeholder="Last name:"
                      onChange= {(event) => this.handleUserInput(event) }
                      value={this.state.firstName}
                      required
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <label htmlFor="email" className="mb-4">Last Name</label>
                    <input
                      type="text"
                      className="page-form__custom-input form-control"
                      id="lastName"
                      name="lastName"
                      aria-describedby="emailHelp"
                      placeholder="First name:"
                      onChange= {(event) => this.handleUserInput(event) }
                      value={this.state.lastName}
                      required
                    />
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="email" className="mb-4">Email</label>
                  <input
                    type="email"
                    className="page-form__custom-input form-control"
                    id="email"
                    name="email"
                    aria-describedby="emailHelp"
                    placeholder="Email:"
                    onChange= {(event) => this.handleUserInput(event) }
                    value={this.state.email}
                    required
                  />
                </div>
                <div className="form-group mb-5">
                  <label htmlFor="password" className="mb-4">Password</label>
                  <input
                    type="password"
                    className="page-form__custom-input form-control"
                    id="password"
                    name="password"
                    placeholder="Password"
                    onChange= {(event) => this.handleUserInput(event) }
                    value={this.state.password}
                    required
                  />
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                    <button
                      className="create-account-with-facebbook-btn social-create-button btn w-100"
                    >
                      <i className="fa fa-facebook mr-2" aria-hidden="true"></i>
                      Facebook
                    </button>
                  </div>
                  <div className="col-12 col-lg-6">
                    <button
                      className="create-account-with-google-plus-btn social-create-button btn w-100"
                    >
                      <i className="fa fa-google-plus mr-2" aria-hidden="true"></i>
                      Google plus
                    </button>
                  </div>
                </div>
                <div className="form-check pl-0">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="policy-check"
                  />
                  <label
                    className="form-check-label form-check-danger-text d-flex align-items-center"
                    htmlFor="policy-check"
                  >I agree to the terms and policy.</label
                  >
                </div>
                <button
                  type="button"
                  className="page-form__form-action w-100 btn btn-primary"
                  onClick={(event => { this.handleSubmit(event) })}
                >
                  Sign Up !
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
}

const mapStateToProps = (response) => ({response});
export default connect(mapStateToProps, null)(Signup);
