import { AppSettingsService } from "./appSettings";

export const EndPoints = {
    REGISTER_API_ENDPOINT: 'signup',
    LOGIN_API_ENDPOINT: 'login',
    GET_COUNTRY_LIST: 'country',
    GET_TIMEZONE_LIST: 'timezone',
    PUT_BASIC_INFORMATION: 'oauth/users',
    SAVE_ONBOARDING_PROGRESS: 'users/onboardingsteps',
    GET_ONBOARDING_SKILLS: 'users/onboarding/info',
    LOAD_SKILLS: 'users/onboardingskills',
    CHECK_INVITE: 'users/validateInviteCode',
    SET_PASSWORD: 'users/setPassword',
    ON_BOARDING: 'users/onboardingsteps',
    GET_CONTRACTS: 'users/onboardingsteps/contract',
    UPLOAD_CONTRACTS: 'users/onboardingsteps/contract',
    UPLOAD_FILE_TO_AWS: 'users/onboardingsteps/contractnew/',
    FORGOT_PASSWORD: 'users/forgotPassword',
    GET_ONBOARDING_STATUS: 'users/onboardingsteps/',
    GET_USER_CREDITS: 'users/',
    GET_USER_DATA: 'oauth/users',
    COMMENT_API: 'comments/entity/',
    //POST_COMMENT: 'comments/entity/'
    BIDMODAL_API: 'contract/',
    POST_BIDMODAL: 'contracts/',
    GET_OPEN_CONTRACTS: 'contracts/projects',
    GET_STORY:'project/',
    GET_BID_DETAILS: 'users/bids/',
    CONTRACTHISTORY_API: 'users/contracts/assigned',
    GET_STORY_API: 'stories/',
    COMMENT_EDIT_API: 'comments/',
    CHANGE_USER_STATUS_API: 'users/status/',
    GET_STATUS: 'contracts/status'
}


export const getUrl = async (key) => {
    return await AppSettingsService.getBaseUrl() + `${key? key : ''}`;
}