import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";

// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
// import Footer from "components/Footer/Footer.js";
// import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
//
import routes from "../routes.js";


// import HomePage from "./views/HomePage/HomePage";


export default function Dashboard(props) {
  const { ...rest } = props;

  const getRoute = () => {
    return window.location.pathname !== "/home";
  };

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/default") {
        return (
          <Route
            path={ prop.path }
            component={prop.component}
            key={prop.path}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Header></Header>
      <Switch>
        {getRoutes(routes)}
        <Redirect from="/" to="/home" />
      </Switch>
      <Footer></Footer>
    </>
  );
}
