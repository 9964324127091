// import Buttons from "views/Components/Buttons.js";
// import Calendar from "views/Calendar/Calendar.js";
// import Charts from "views/Charts/Charts.js";
// import Dashboard from "views/Dashboard/Dashboard.js";
// import ErrorPage from "views/Pages/ErrorPage.js";
// import ExtendedForms from "views/Forms/ExtendedForms.js";
// import ExtendedTables from "views/Tables/ExtendedTables.js";
// import FullScreenMap from "views/Maps/FullScreenMap.js";
// import GoogleMaps from "views/Maps/GoogleMaps.js";
// import GridSystem from "views/Components/GridSystem.js";
// import Icons from "views/Components/Icons.js";
// import LockScreenPage from "views/Pages/LockScreenPage.js";
// import LoginPage from "views/Pages/LoginPage";
// import Notifications from "views/Components/Notifications.js";
// import Panels from "views/Components/Panels.js";
// import PricingPage from "views/Pages/PricingPage.js";
// import projectList from "views/projectList/projectList.js"
// import ReactTables from "views/Tables/ReactTables.js";
// import RegisterPage from "views/Pages/RegisterPage.js";
// import RegularForms from "views/Forms/RegularForms.js";
// import RegularTables from "views/Tables/RegularTables.js";
// import SweetAlert from "views/Components/SweetAlert.js";
// import TimelinePage from "views/Pages/Timeline.js";
// import Typography from "views/Components/Typography.js";
// import UserProfile from "views/Pages/UserProfile.js";
// import ValidationForms from "views/Forms/ValidationForms.js";
// import VectorMap from "views/Maps/VectorMap.js";
// import Widgets from "views/Widgets/Widgets.js";
// import Wizard from "views/Forms/Wizard.js";
// import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
// import LocalActivityIcon from '@material-ui/icons/LocalActivity';
// import SprintEdit from "views/sprintEdit/sprintEdit.js";
// import EditProfile from "views/Pages/EditProfile.js";
// import UserList from "views/UserList/UserList.js";
// import FileCopyIcon from '@material-ui/icons/FileCopy';
// import PaymentIcon from '@material-ui/icons/Payment';
//
// import DoerList from "views/DoerList/DoerList";
// import DoerEdit from "views/DoerEdit/DoerEdit";
// import FilePopUp from "components/FilePopUp/FilePopUp";
// import ResetPassword from "views/Pages/ResetPassword";
// import ForgotPassword from "views/Pages/ForgotPassword";
// import Credit from "views/Pages/Credit";
// import openContractList from "views/marketPlace/openContractList"
// import EditContract from "./views/marketPlace/EditContract";
// import UserStories from "views/Pages/UserStories";
// import PaymentList from "views/Payment/PaymentList";
// import UserStoryDetail from "views/Pages/UserStoryDetail";

import Login from "./views/Login/Login";
import Signup from "./views/SignUp/Signup";
import HomePage from "./views/HomePage/HomePage";

var dashRoutes = [
  { path: "/home", name: "Home Page", component: HomePage, layout: "/default" },
  { path: "/login", name: "Login", component: Login, layout: '/default' },
  { path: "/sign-up", name: "Sign up", component: Signup, layout: '/default' },
];

export default dashRoutes;
